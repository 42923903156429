<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12" style="text-align: justify">
          <div class="headline style-3">
            <h5>Biuro Storno</h5>
            <h2>Kalkulatory</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        v-for="(key, val) in calculators"
        :key="key"
        class="row"
      >
        <div class="col-sm-12">
          <div class="services-boxes wow fadeInUp animated" style="visibility: hidden;">
            <div class="services-boxes-content">
              <h3 style="  text-transform: inherit;">
                <a :href="key" target="_blank">{{ val }}</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <div class="col-sm-12">
        <div class="wow fadeInDown animated" style="visibility: hidden;">
          <div class="services-boxes-content">
            <p style="text-align: center; margin-bottom: 0px;">
              Powyższe kalkulatory pochodzą ze strony: <a href="http://www.kalkulatory.gofin.pl">kalkulatory.gofin.pl</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  head: {
    title: 'Kalkulator - Biuro rachunkowe Poznań "STORNO"'
  },
  data () {
    return {
      calculators: {
        'Kalkulator odsetek od zaległości podatkowych i składkowych': 'http://www.kalkulatory.gofin.pl/Kalkulator-odsetek-od-zaleglosci-podatkowych,12.html',
        'Kalkulator odsetek ustawowych': 'http://www.kalkulatory.gofin.pl/Kalkulator-odsetek-ustawowych,12.html',
        'Kalkulator odsetek umownych/własnych': 'http://www.kalkulatory.gofin.pl/Kalkulator-odsetek-umownych-wlasnych,12.html',
        'Kalkulator płac (umowa zlecenia i o dzieło - pobór zaliczki)': 'http://www.kalkulatory.gofin.pl/Kalkulator-plac-umowa-zlecenia-i-o-dzielo-pobor-zaliczki,12.html',
        'Kalkulator płac (umowa zlecenia i o dzieło opodatkowana ryczałtem)': 'http://www.kalkulatory.gofin.pl/Kalkulator-plac-umowa-zlecenia-i-o-dzielo-opodatkowana-ryczaltem,12.html',
        'Kalkulator wynagrodzeń': 'http://www.kalkulatory.gofin.pl/Kalkulator-wynagrodzen,12.html',
        'Kalkulator wynagrodzenia chorobowego': 'http://www.kalkulatory.gofin.pl/Kalkulator-wynagrodzenia-chorobowego,12.html',
        'Kalkulator stażu urlopowego': 'http://www.kalkulatory.gofin.pl/Kalkulator-stazu-urlopowego,12.html',
        'Kalkulator urlopów macierzyńskich i rodzicielskiego': 'http://www.kalkulatory.gofin.pl/Kalkulator-urlopow-macierzynskich-i-rodzicielskiego,12.html',
        'Kalkulator stażu pracy': 'http://www.kalkulatory.gofin.pl/Kalkulator-stazu-pracy,12.html',
        'Kalkulator wieku emerytalnego': 'http://www.kalkulatory.gofin.pl/Kalkulator-wieku-emerytalnego,12.html',
        'Kalkulator VAT (brutto-netto)': 'http://www.kalkulatory.gofin.pl/Kalkulator-vat-brutto-netto,12.html',
        'Kalkulator stawek notarialnych': 'http://www.kalkulatory.gofin.pl/Kalkulator-stawek-notarialnych,12.html'
      }
    }
  }
}
</script>
